import ccAmericanExpress from '@/images/icons/ccAmericanExpress.png';
import ccDiscover from '@/images/icons/ccDiscover.png';
import ccMastercard from '@/images/icons/ccMastercard.png';
import ccOther from '@/images/icons/ccOther.png';
import ccVisa from '@/images/icons/ccVisa.png';

export default class PaymentCardBrand {
	static none = 0;
	static visa = 1;
	static mastercard = 2;
	static americanExpress = 3;
	static discover = 4;
	static other = 255;

	static options = [
		{ id: this.none, name: 'None', image: '' },
		{ id: this.visa, name: 'Visa', image: ccVisa },
		{ id: this.mastercard, name: 'Mastercard', image: ccMastercard },
		{ id: this.americanExpress, name: 'American Express', image: ccAmericanExpress },
		{ id: this.discover, name: 'Discover', image: ccDiscover },
		{ id: this.other, name: 'Other', image: ccOther },
	];

	static display(value) {
		const item = this.options.find(x => x.id === value);
		return item ? item.name : '';
	}

	static isValid(value) {
		return value === this.none || value === this.visa || value === this.mastercard || value === this.americanExpress || value === this.discover || value === this.other;
	}
}
